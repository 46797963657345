import React from 'react';
import {Content, PolicyWrapper, Text, Title} from "./style";
import {Container} from "../../common/style/Container";
import {Link} from 'react-router-dom';
import {ROUTS} from "../../common/constans/routs";

export const Policy = () => {
    return (
        <PolicyWrapper>
            <Container>
                <Content>
                    <Title>Политика в отношении обработки персональных данных</Title>
                    <Text>
                        Политика в отношении обработки персональных данных (далее — Политика) разработана во исполнение
                        требований абз. 3 п. 3 ст. 17 Закона Республики Беларусь от 07.05.2021 № 99-З «О защите
                        персональных данных» в целях обеспечения защиты персональных данных, прав и свобод физических
                        лиц (далее — Пользователей) при обработке их персональных данных ООО «Веон-консалт», УНП
                        193082231, адрес: г. Минск, 220068, ул. Сурганова, 57Б, пом. 187 (далее — Компания), которые
                        Компания может получить при использовании сайта <Link to={ROUTS.HOME}>VEON-TECH.by</Link> (далее
                        — Сайт) и/или данные о
                        третьем лице по поручению и в интересах которых действует Пользователь во время использования
                        Сайта, в том числе при заполнении форм, размещенных на Сайте.
                        Текст настоящей Политики размещается Компанией на своем сайте в сети Интернет по адресу:
                        <Link to={ROUTS.POLICY}> VEON-TECH.by/privacy </Link>
                        в соответствии с п. 4. ст. 17 Закона Республики Беларусь от 07.05.2021 №
                        99-З «О защите персональных данных».
                    </Text>
                    <Text>
                        <span>Наши контакты:</span>
                    </Text>
                    <Text>
                        ООО «Веон-консалт», УНП 193082231, адрес: г. Минск, 220068, ул. Сурганова, 57Б, пом. 187
                        support@veon-tech.by.
                    </Text>
                    <ol>
                        <li>ОБЩИЕ ПОЛОЖЕНИЯ
                            <ol>
                                <li>
                                    <span>Персональными данными</span> является любая информация, относящаяся к
                                    идентифицированному
                                    физическому лицу или физическому лицу, которое может быть идентифицировано, то есть
                                    физическому лицу, которое может быть прямо или косвенно определено (в частности
                                    через фамилию, собственное имя, отчество, дату рождения, идентификационный номер
                                    либо через один или несколько признаков, характерных для его физической,
                                    психологической, умственной, экономической, культурной или социальной
                                    идентичности).
                                </li>
                                <li>
                                    <span>Обработка персональных данных Компанией</span> включает в себя любое действие
                                    или
                                    совокупность действий, совершаемые с персональными данными, включая сбор,
                                    систематизацию, хранение, изменение, использование, обезличивание, блокирование,
                                    распространение, предоставление, удаление персональных данных.
                                </li>
                                <li>
                                    Компания обрабатывает персональные данные как с использованием средств
                                    автоматизации, так и без использования средств автоматизации.
                                </li>
                                <li>
                                    Пользователь может дать согласие на обработку персональных данных при заполнении
                                    форм на Сайте путем проставления соответствующей отметки и иными способами,
                                    предусмотренными Политикой.
                                </li>
                                <li>
                                    Обработка персональных данных ограничивается достижением конкретных, заранее
                                    заявленных и законных целей. Компания не осуществляет обработку персональных данных,
                                    не совместимую с целями их обработки.
                                </li>
                            </ol>
                        </li>
                        <li>СОСТАВ ПЕРСОНАЛЬНЫХ ДАННЫХ, ЦЕЛИ, ПРАВОВЫЕ ОСНОВАНИЯ И СРОК ХРАНЕНИЯ ДАННЫХ
                            <ol>
                                <li>
                                    Компания является оператором и обрабатывает следующие персональные данные
                                    Пользователей Сайта:
                                    <ol>
                                        <li>
                                            Фамилию, имя, отчество, должность, наименование компании, которую
                                            представляет Пользователь, адрес электронной почты, номер контактного
                                            телефона в целях обработки заявок, оставленных Пользователем, заключения с
                                            Пользователем, либо компаниями/физическими лицами, от имени которых
                                            действует Пользователь, договоров оказания услуг/выполнения работ,
                                            коммуникации по проектам, предоставления доступа к корпоративным системам
                                            для реализации проектов. Срок хранения персональных данных составляет 6
                                            месяцев с даты окончания или расторжения договора, либо в иные сроки,
                                            установленные законодательством Республики Беларусь.
                                        </li>
                                        <li>
                                            Фамилию, имя, отчество, должность, наименование компании, которую
                                            представляет Пользователь, адрес электронной почты, номер контактного
                                            телефона в целях направления коммерческих предложений, сообщений
                                            рекламно-информационного характера на основании согласия Пользователя при
                                            заполнении формы. Срок хранения персональных данных, предоставленных для
                                            рассылки, ограничивается завершением маркетинговых кампаний или
                                            <span> отказом
                                            Пользователя от рассылки в любое время с помощью нажатия на ссылку
                                            «Отписаться» в электронном письме или направления уведомления на почту
                                            support@veon-tech.by.
                                            </span>
                                        </li>
                                        <li>
                                            Фамилию, имя, отчество, номер телефона, адрес электронной почты, любую
                                            информацию, предоставленную Пользователем в резюме в целях рассмотрения
                                            кандидатуры на соответствующую вакансию Компании. Основанием для обработки
                                            персональных данных является согласие на обработку данных, выраженное при
                                            заполнении формы «Отправить резюме». Срок хранения данных, предоставленных
                                            для трудоустройства, составляет 12 месяцев с момента принятия решения о
                                            приеме на работу или об отказе в трудоустройстве.
                                        </li>
                                        <li>
                                            Фамилию, имя, отчество, номер телефона, адрес электронной почты, содержание
                                            (текст) обращения при заполнении формы обратной связи, обращении по е-mail
                                            либо по телефону в целях ответа на обращение Пользователя. Основанием
                                            обработки таких данных является легитимный интерес. Срок хранения
                                            персональных данных не превышает время, необходимое для ответа на обращение,
                                            также может быть увеличен до истечения предусмотренного законом срока
                                            исковой давности.
                                        </li>
                                        <li>
                                            Обезличенные технические данные с устройств Пользователя (информация о
                                            пользовательских действиях на Сайте, IP-адреса, данные о местоположении,
                                            cookie-файлы), собираемая в том числе с помощью сервисов аналитики Yandex
                                            Metrika,
                                            <a target={'_blank'} rel="noreferrer"
                                               href="https://support.google.com/analytics/answer/7667196?hl=ru&roistat_visit=311498">
                                                Google Analytics </a> в целях обеспечения эффективной работы Сайта на
                                            основании легитимного интереса и согласия Пользователя, которое выражается в
                                            продолжении использования Сайта после предупреждения об использовании
                                            данных. Срок хранения данных, автоматически собранных при посещении
                                            Пользователем Сайта, не превышает 14 месяцев. Пользователь проинформирован,
                                            что более подробную информацию о cookie-файлах и принципах их работы можно
                                            найти на ресурсе <a target={'_blank'} rel="noreferrer"
                                                                href="https://www.aboutcookies.org/?roistat_visit=311498"> aboutcookies.org</a> .
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>ПРАВА ПОЛЬЗОВАТЕЛЯ КАК СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ
                            <ol>
                                <li>Пользователь имеет право:
                                    <ol>
                                        <li>
                                            на отзыв согласия на обработку персональных данных;
                                        </li>
                                        <li>на получение информации, касающейся обработки своих персональных данных;
                                        </li>
                                        <li>на изменение персональных данных;</li>
                                        <li>на получение информации о предоставлении персональных данных третьим
                                            лицам;
                                        </li>
                                        <li>требовать прекращения обработки персональных данных и (или) их удаления;
                                        </li>
                                        <li>на обжалование действий (бездействия) и решений Компании, связанных с
                                            обработкой персональных данных.
                                        </li>
                                    </ol>
                                </li>
                                <li>Для реализации своих прав, указанных в п. 3.1. Политики, Пользователю необходимо
                                    направить Компании соответствующее заявление одним из способов:
                                    <ol>
                                        <li>в письменной форме по адресу: Республика Беларусь,г. Минск, 220068, ул.
                                            Сурганова, 57Б, пом. 187
                                        </li>
                                        <li>в форме, посредством которой получено согласие.</li>
                                    </ol>
                                </li>
                                <Text>
                                    Заявление субъекта персональных данных должно содержать фамилию, собственное имя,
                                    отчество (если таковое имеется) Пользователя, адрес, дату рождения,
                                    идентификационный номер или номер документа, удостоверяющего личность (при
                                    отсутствии идентификационного номера), в случаях, если эта информация указывалась
                                    при даче своего согласия или обработка персональных данных осуществляется без
                                    согласия субъекта персональных данных; изложение сути требований, личную подпись
                                    либо электронную цифровую подпись.
                                </Text>
                                <Text>
                                    При отзыве согласия на обработку персональных данных Пользователь также отказывается
                                    от получения рекламно-информационных рассылок.
                                </Text>
                                <li>
                                    Компания обязуется ответить на заявление Пользователя в сроки, установленные
                                    белорусским законодательством.
                                </li>
                            </ol>
                        </li>
                        <li>УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ И ПЕРЕДАЧИ ИХ ТРЕТЬИМ ЛИЦАМ
                            <ol>
                                <li>В отношении персональных данных сохраняется конфиденциальность, кроме случаев
                                    добровольного предоставления Пользователем информации о себе в общем доступе
                                    неограниченному кругу лиц. В данном случае Пользователь соглашается с тем, что
                                    определенная часть его персональных данных становится общедоступной.
                                </li>
                                <li>Компания вправе передать персональную информацию Пользователя третьим лицам в
                                    следующих случаях:
                                    <ol>
                                        <li>Пользователь выразил согласие на такие действия.
                                        </li>
                                        <li>Передача необходима для исполнения определенного соглашения или договора
                                            между Компанией и Пользователем.
                                        </li>
                                        <li>Передача предусмотрена белорусским или иным применимым законодательством в
                                            рамках установленной законодательством процедуры.
                                        </li>
                                    </ol>
                                </li>
                                <li>Компания может передавать персональные данные Пользователя третьим лицам,
                                    перечисленным ниже, в целях, указанных в п. 2.1. Политики.
                                    <Text>Яндекс Метрика — сервис веб-аналитики, предоставляемый ООО «Яндекс». Адрес: г.
                                        Москва, ул. Льва Толстого, д. 16, 119021. <a target={'_blank'} rel="noreferrer"
                                                                                     href="https://yandex.ru/legal/confidential/?roistat_visit=311498">Политика
                                            конфиденциальности Яндекс</a> .
                                    </Text>
                                    <Text>Google Analytics — сервис веб-аналитики, предоставляемый компанией Google,
                                        Inc. Адрес: Google, Google Data Protection Office, 1600 Amphitheatre Pkwy,
                                        Mountain View, CA 94043, USA. <a target={'_blank'} rel="noreferrer"
                                                                         href="https://policies.google.com/privacy?hl=ru&roistat_visit=311498#infocollect">Политика
                                            конфиденциальности Google</a> .
                                    </Text>
                                    <Text>Хантфлоу — сервис по ведению базы кандидатов, предоставляемый ООО «Хантфлоу».
                                        Адрес: 127015, г. Москва, внутригородская территория города муниципальный округ
                                        Бутырский, ул. Новодмитровская, д. 2, корп. 2, помещение 27А. <a
                                            target={'_blank'} rel="noreferrer"
                                            href="https://huntflow.ru/docs/privacy-policy?roistat_visit=311498">Политика
                                            конфиденциальности Хантфлоу.</a>
                                    </Text>
                                    <Text>Pipedrive — сервис по управления взаимоотношениями с клиентами,
                                        предоставляемый Pipedrive Florida. Адрес: 490 1st Ave South, Suite 800 St.
                                        Petersburg, FL 33701, USA. <a target={'_blank'} rel="noreferrer"
                                                                      href="https://www.pipedrive.com/en/privacy?roistat_visit=311498">Политика
                                            конфиденциальности Pipedrive</a> .
                                    </Text>
                                </li>
                                <li>Компания при обработке персональных данных принимает необходимые и достаточные
                                    организационные и технические меры для защиты персональных данных от неправомерного
                                    доступа к ним, а также от иных неправомерных действий в отношении персональных
                                    данных.
                                </li>
                            </ol>
                        </li>
                        <li>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
                            <ol>
                                <li>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся
                                    обработки его персональных данных, обратившись к Компании по электронной почте
                                    support@veon-tech.by.
                                </li>
                                <li>Все предложения или вопросы по настоящей Политике конфиденциальности следует
                                    направлять на адрес электронной почты support@veon-tech.by.
                                </li>
                                <li>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу
                                    <Link to={ROUTS.POLICY}> VEON-TECH.by/privacy/</Link>.
                                </li>
                                <li>Используя Сайт, отправляя данные, запросы, резюме, заполняя анкету, форму, заявку
                                    Пользователь ПОДТВЕРЖДАЕТ, ЧТО ПРОЧЁЛ ПОЛИТИКУ И ВЫРАЖАЕТ СВОЁ СВОБОДНОЕ,
                                    ИНФОРМИРОВАННОЕ И ОДНОЗНАЧНОЕ СОГЛАСИЕ на сбор, обработку, распространение,
                                    предоставление и хранение указанных в ней персональных данных, осуществляемые без
                                    использования средств автоматизации, а также с использованием средств автоматизации,
                                    в течение указанного для этого срока.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Content>
            </Container>
        </PolicyWrapper>
    );
}

