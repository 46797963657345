
export const mistakeData: any = [
    {
        head: 'Отсутствие чёткого призыва к действию',
        text: `Разработка веб-сайта должна начинаться с четкого понимания, что должен сделать пользователь, попадая на ваш сайт. Используйте контент, который описывает ценность ваших продуктов или услуг, а также убедительную фразу, побуждающую к действию.`,
        ul: ['Добавить в корзину', 'Зарегистрироваться', 'Узнать больше']
    },
    {
        head: 'Отсутствие аналитики для измерения эффективности',
        text: `Встраивание инструментов отслеживания производительности достаточно быстро окупают стоимость разработки сайта. Подключив сайт к Google Analytics, вы сможете на основе получаемой аналитики корректировать рекламные компании и настраивать онлайн-маркетинг на аудиторию, которая принесет вам прибыль.`,
        ul: null
    },
    {
        head: 'Размытая информация о бренде',
        text: `Важно сразу же дать понять  пользователям, кто вы и чем занимаетесь. Разместите на главном баннере описание вашей услуги или товара. Меньше говорите о себе и больше говорите с аудиторией, чтобы решить их болевые точки. Это разговор, который укрепляет доверие и ведет к увеличению продаж.`,
        ul: null
    },
    {
        head: 'Слабая поисковая оптимизация (SEO)',
        text: `Идеально оптимизированная страница веб-сайта способна увеличить трафик на 20%.`,
        ul: ['Используйте длинные ключевые фразы, вам будет легче ранжироваться.', 'Регулярная публикация качественного контента.', 'Поощряйте социальную активность. Добавьте кнопки социальных сетей.']
    },
]