import styled from "styled-components";

export const PartyIntelResultWrapper = styled.div`
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`
