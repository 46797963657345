import styled from "styled-components";

export const CaseHappyElephantWrapper = styled.div`
  //  overflow: hidden;

`





