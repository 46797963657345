import styled from "styled-components";

export const СasesWrapper = styled.div`
  padding-top: 140px;
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
`



