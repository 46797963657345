import styled from "styled-components";

export const AboutWrapper = styled.div`

`







